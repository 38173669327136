import './bootstrap';

import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn: "https://8191ecbe770028a965c9081ef748d543@o4506067031293952.ingest.sentry.io/4506067089096704",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', 'entropy-services.test', /^\//],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
